class ConfirmSetStatusReadyToToPrint extends ConfirmDialog {
    private switchId: string;
    private switchTypeId: string;

    constructor(header:string, content:string, switchId:string, switchTypeId:string, triggerGlobal = true, successCallback: Function = null) {
        super(header, content, triggerGlobal, successCallback, );
        this.switchId = switchId;
        this.bindHandlers();
    }

    bindHandlers() {
        this.setStatusToReadyToPrint.bind(this);
        $(document).on("click", "#btnConfirm", this.setStatusToReadyToPrint.bind(this));
    }

    public setStatusToReadyToPrint(e: Event) {
        var url = "/Client2/Switch/SetSwitchStatusToReadyToPrint";

        var self = this;

        $.ajax({
            type: "POST",
            url: url,
            data: { SwitchId: self.switchId },
            success:  () => {
                $('#confirmPrintedStatusModal').modal('hide');
                window.location.href = "/Client2/Home";
            },
            error: function (xhr, ajaxOptions, thrownError) {
                showAjaxStatusWindow('#WebResources_CLICKSWITCH_ERROR', thrownError, false);
            }
        });
    }
}